import axios from 'axios';
import Cookies from 'js-cookie';
import { auth } from './auth';

const API_ENDPOINT = 'https://api.barbace.nl/v1/e/collect';
const CID = process.env.GATSBY_COL_CONFIGURATION_ID;

interface UserParams {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
}

declare global {
    interface Window {
        Cookiebot: {
            consent: {
                marketing: boolean;
            };
        };
    }
}

export const trackEvent = async ({ eventType, userParams }: Props) => {
    
    if (typeof window !== 'undefined' && window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent.marketing) {
        const payload: { [key: string]: any } = {
            et: eventType,
            cid: CID,
            fu: window.location.href
        };

        const userID = Cookies.get('uid');
        if (userID) payload.uid = userID;

        if (userParams?.firstName) payload.ufn = userParams.firstName;
        if (userParams?.lastName) payload.uln = userParams.lastName;
        if (userParams?.email) payload.uem = userParams.email;
        if (userParams?.phone) payload.uph = userParams.phone;

        let isRetried = false;

        const sendEvent = async () => {
            try {
                let response = await axios.post(
                    API_ENDPOINT,
                    payload,
                    { withCredentials: true }
                );
        
                if (response.data?.uid) {
                    Cookies.set('uid', response.data.uid, { expires: 365, secure: true, sameSite: 'Strict' });
                }
                return response;
            } catch (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403) && !isRetried) {
                    isRetried = true; // Set isRetried before retrying
                    await auth(); // Call getToken to refresh token
                    return sendEvent(); // Retry sendEvent after refreshing the token
                } else {
                    throw error;
                }
            }
        };
        return await sendEvent(); // Call sendEvent initially
    }
};

interface Props {
    eventType: 'page_view' | 'lead' | 'contact' | 'schedule';
    userParams?: UserParams;
}